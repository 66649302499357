import { render, staticRenderFns } from "./ModalNotifyInfoCreateFlow.vue?vue&type=template&id=502f299a&"
import script from "./ModalNotifyInfoCreateFlow.vue?vue&type=script&lang=js&"
export * from "./ModalNotifyInfoCreateFlow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports