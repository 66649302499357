<template>
  <div>
    <v-form @submit.prevent="checkSubmit" :id="`check-create-cmu-exam-form-${courseData._id}`" scrollable>
    <v-dialog
      v-model="dialog"
      :width="dialogSize"
      scrollable
    >
     <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="#745CA8"
            dark
            v-bind="attrs"
            v-on="on"
        >
            <v-icon>mdi-plus</v-icon>
            Create
        </v-btn>
      </template>
      <!-- <v-card> -->
        <!-- <v-card-title>
            <b>Create CMU Exam</b>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false" >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title> -->
        <!-- <v-card-text> -->
          <v-stepper v-model="step" alt-labels>
            <v-stepper-header>
              <v-stepper-step
                  :complete="step > 1"
                  step="1"
              >
                  รูปเบบการสร้าง
              </v-stepper-step>

              

              <v-divider></v-divider>

              <v-stepper-step
                  :complete="step > 2"
                  step="2"
              >
                  เลือก Section
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card outlined class="mb-2">
                  <v-row >
                      <v-col class="d-flex justify-center" style="flex-wrap: wrap;">
                          <template>
                              <v-checkbox
                                  class="ml-2 pt-5"
                                  v-model="selectionOptionCreated"
                                  label="รวม section"
                                  value="sumAll"
                                  :key="sumAll"
                              ></v-checkbox>

                              <v-checkbox
                                  class="ml-2 pt-5"
                                  v-model="selectionOptionCreated"
                                  label="เเยก section"
                                  value="splitAll"
                                  :key="splitAll"
                              ></v-checkbox>
                          </template>
                      </v-col>
                  </v-row>
                </v-card>
                <v-row class="mb-2 mt-2">
                  <v-col>
                    <v-btn
                      color="dark"
                      text
                      @click="dialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="text-right mr-2">
                    <v-btn
                      color="success"
                      @click="validateNextStepper(2)"
                      :key="`submit-btn-${courseData._id}`"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card  outlined class="mb-2" style="overflow-x: scroll; max-height: 350px;" >
                  <v-row>
                    <v-col class="d-flex justify-center" style="flex-wrap: wrap;">
                        <template>
                        <v-checkbox
                          class="mt-0 pt-5"
                          v-model="selectAll"
                          label="เลือกทั้งหมด"
                          value="checkAll"
                        ></v-checkbox>
                        <div class="break"></div>
                        </template>
                        <template v-for="(section,i) in sectionArr">
                          <v-checkbox
                            style="width: 4rem;"
                            class="mt-0"
                            v-model="selected"
                            :label="filterSection(section)"
                            :value="section"
                            :key="`checkbox-${i}`"
                          ></v-checkbox>
                          <div class="break" :key="`break-${i}`"></div>
                        </template>
                    </v-col>
                  </v-row> 
                </v-card>
                <v-row class="mb-2 mt-2">
                  <v-col>
                    <v-btn
                      color="dark"
                      text
                      @click="validatePreviousStepper(1)"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="text-right mr-2">
                    <v-btn
                      color="success"
                      type="submit"
                      :form="`check-create-cmu-exam-form-${courseData._id}`"
                      :key="`submit-btn-${courseData._id}`"
                      
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
         
    </v-dialog>
    
    
    </v-form>
  </div>
</template>

<script>
    export default {
      name: 'CreateCMUExamModal',
      props: {
        courseData: {
          type: Object
        },
        openModal: Boolean
      },
      computed: {
        selectAll: {
            get: function () {
              // return this.sectionArr ? this.selected.length == this.sectionArr.length : false
              return true
            },
            set: function (value) {
              let selected = []

              if (value) {
                this.sectionArr.forEach(function (section) {
                  selected.push(section)
                })
              }

              this.selected = selected
            }
        }

      },
      data () {
        return {
          dialog: false,
          dialogSize: 500,
          selected: [],
          sectionArr: [],
          section: [] , 
          step : 1 , 
          selectionOptionCreated : "", 

        }
      },
      created (){
        // this.createSectionArr()
        // console.log('create section', this.courseData)
        this.createSectionArr(this.courseData)
      },
      components: {
      },
     
      watch: {
        courseData: function (newVal, oldVal) {
          console.log('Prop changed: ', newVal, ' | was: ', oldVal)
          this.createSectionArr(newVal)
        },
        openModal: function () {
          this.dialog = true
        },
        dialog: function () {
          this.$emit('modalClose', false)
        }
        
      },
      methods: {
        validateNextStepper(next){
          // console.log(step)
          switch(next){
            case 2 :
              if(this.selectionOptionCreated){
                this.step = next
              }
              break;
            default:
              break;
          }
        },
        validatePreviousStepper(back){
          switch(back){
            case 1 :
                this.step = back
              break;
            default:
              break;
          }
        },
        createSectionArr (courseData) {
          if(courseData.sections){
            this.sectionArr = []
            courseData.sections = courseData.sections.sort()
            for(let i=0;i<courseData.sections.length;i++){
              this.sectionArr.push(courseData.sections[i])
            }
          }
        },
        checkSubmit () {
          console.log("submit" , this.selected)
          if(this.selected.length > 0){
            this.$emit('modalSubmit', this.selected , this.selectionOptionCreated)
            this.selected = []
            this.dialog = false
          }
          
        },
        filterSection (section) {
          let sectionLec = section.substring(0, 3)
          let sectionLab = section.substring(3)
          if (sectionLec === '000' && sectionLab !== '000') {
            return `Lab.${sectionLab}`
          }else if(sectionLec !== '000' && sectionLab === '000'){
            return `Lec.${sectionLec}`
          }
          return 0;
        },
       
      },
    }
</script>