import { apiLmsManagement } from "@/axios.js"
export default {
    async createCanvas(data) {
        try {
            let response = await apiLmsManagement.post(`/api/CreateCanvas`, data)
            response['data'].status = true
            return response['data']
        } catch (error) {
            console.log(`error-:`, error.message)
            return {
                status: false,
                message: 'not found error'
            }
        }
    },
    async createExam(data) {
        try {
            let response = await apiLmsManagement.post(`/api/CreateExam`, data)
            response['data'].status = true
            return response['data']
        } catch (error) {
            console.log(`error-:`, error.message)
            return {
                status: false,
                message: 'not found error'
            }
        }
    },
    async createMoodle(data) {
        try {
            let response = await apiLmsManagement.post(`/api/CreateMoodle`, data)
            response['data'].status = true
            return response['data']
        } catch (error) {
            console.log(`error-:`, error.message)
            return {
                status: false,
                message: 'not found error'
            }
        }
    },
    async createMango(data) {
        try {
            let response = await apiLmsManagement.post(`/api/CreateMango`, data)
            response['data'].status = true
            return response['data']
        } catch (error) {
            console.log(`error-:`, error.message)
            return {
                status: false,
                message: 'not found error'
            }
        }
    },
    async getJobStatus(lmsType, lmsId) {
        try {
            let response = await apiLmsManagement.get(`/api/GetEnrollStatusByLms?lms_type=${lmsType}&course_id=${lmsId}`, {})
            response['data'].status = true
            return response['data']
        } catch (error) {
            console.log(`error-:`, error.message)
            return {
                status: false,
                message: 'not found error'
            }
        }
    },
    async checkGrantStatus(email) {
        try {
            let response = await apiLmsManagement.get(`/api/CheckGrantAccess?email=${email}`, {})
            response['data'].status = true
            return response['data']
        } catch (error) {
            console.log(`error-:`, error.message)
            return {
                status: false,
                message: 'not found error'
            }
        }
    },
   

}